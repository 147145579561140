<template>
  <div class="loading-container" v-if="isLoading">
    <Spinner />
  </div>
  <div class="b2b-members-invitation-wrapper">
    <div class="error-container">{{errorMessage}}</div>
    <div class="existing-subscription-info" v-if="currentUser.subscription.quantity">
      <div class="existing-subscription-info-row">Current plan: {{currentUser.subscription.interval}} plan</div>
      <div class="existing-subscription-info-row">Current price: {{currentPricePerInterval}}</div>
    </div>
    <div class="subscription-variants" v-if="!currentUser.subscription.quantity">
      <div
        class="subscription-item"
        :class="{'selected-subscription': selectedPrice === item.price_id}"
        v-for="item in subscriptionVariants"
        :key="item.price_id"
        @click="selectSubscription(item.price_id, item.unit_amount)"
      >
        <div
          class="radio-btn"
          :class="{'active-radio-btn': selectedPrice === item.price_id}"
        >
        </div>
        <div class="subscription-info">
          <div class="subscription-title">
            {{'$' + item.unit_amount / 100 + ' ' + item.name}}
          </div>
          <div class="subscription-description">
            {{getSubscriptionDescription(item.name)}}
          </div>
        </div>
      </div>
    </div>
    <div class="card-container">
      <Card
        v-if="defaultCard"
        :key="defaultCard.id"
        :cardId="defaultCard.id"
        :last4="defaultCard.last4"
        :userDefaultCard="currentUser.subscription.default_payment_method"
        :cardExpirationMonth="defaultCard.exp_month"
        :cardExpirationYear="defaultCard.exp_year"
        :isActionsAvailable="false"
      />
      <div class="new-card-title" v-if="!defaultCard">Enter card credentials:</div>
      <div id="card-element" v-if="!defaultCard">
        <!-- stripe UI will be loaded here -->
      </div>
    </div>
    <div class="email-fields-container">
      <div class="email-field-container"
        v-for="(emailString, index) in emailsList"
        :key="index"
      >
        <CommonInputField
          :width="'320px'"
          :height="'65px'"
          :inputHeight="'54px'"
          :lable="'New member email'"
          :placeholder="'email@example.com'"
          :value='emailsList[index]'
          @updateValue="addMemberEmail($event, index)"
        />
        <div
          class="delete-email-field-btn"
          v-if="emailsList.length > 1"
          @click="removeEmailField(index)"
        >
        </div>
      </div>
      <ActionButton
        :width="'320px'"
        :height="'44px'"
        :text="'Invite more'"
        @click="addEmailField"
      />
    </div>
    <div class="total-amount-info">
      You will be charged {{totalAmount}} dollars/month
    </div>
    <ActionButton
      :width="'320px'"
      :height="'44px'"
      :minHeight="'44px'"
      :text="'Pay and invite'"
      @click="handleMembersInvitation"
    />
  </div>
</template>

<script>
import CommonInputField from '../../common/CommonInputField.vue'
import ActionButton from '../../common/ActionButton.vue'
import Spinner from '../../common/Spinner.vue'
import Card from '../../common/cards/Card.vue'

import * as subscriptionsApi from '../../../api/subscriptionsApi'
import { UserSubscriptionStatus } from '../../../utils/constants'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    CommonInputField,
    ActionButton,
    Spinner,
    Card,
  },
  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      defaultCard: null,
      b2bOwnerSubscription: null,
      subscriptionVariants: [],
      selectedPrice: '',
      amount: 0,
      emailsList: [''],
      errorMessage: '',
      isLoading: true,
    }
  },
  async created() {
    await this.fetchInitialData()
  },
  computed: {
    ...mapState([
      'currentUser',
      'cardsList',
    ]),
    currentPriceValue() {
      if (this.currentUser.subscription.interval) {
        return this.currentUser.subscription.interval === 'month'
          ? 99
          : 950
      } else {
        return this.amount / 100
      }
    },
    currentPricePerInterval() {
      return this.currentUser.subscription.interval === 'month'
        ? `${this.currentPriceValue} dollars/month`
        : `${this.currentPriceValue} dollars/year`
    },
    totalAmount() {
      return this.emailsList.length * this.currentPriceValue
    },
  },
  methods: {
    ...mapActions([
      'updateUserLocally',
      'getCardsList',
    ]),
    async getSubscriptionVariants() {
      this.subscriptionVariants = await subscriptionsApi.getSubscriptionsVariants()
    },
    async setDefaultCardLocally() {
      if (!this.cardsList.length) {
        await this.getCardsList()
      }

      const { subscription: { default_payment_method } } = this.currentUser
      this.defaultCard = this.cardsList.find(card => card.id === default_payment_method)
    },
    handleStripeErrors(error) {
      if (error) {
        this.errorMessage = error.message
      } else {
        this.errorMessage = ''
      }
    },
    async connectStripe() {
      this.stripe = await Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)

      if (this.defaultCard) return

      this.elements = this.stripe.elements()

      const cardStyle = {
        base: {
          color: "black",
          fontFamily: 'Rubik, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "18px",
          "::placeholder": {
            color: "gray"
          }
        },
        invalid: {
          fontFamily: 'Rubik, sans-serif',
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }

      this.card = this.elements.create('card', { style: cardStyle })
      this.card.on('change', event => {
        const { error } = event
        this.handleStripeErrors(error)
      })
      this.card.mount('#card-element')
    },
    async fetchInitialData() {
      try {
        if (!this.currentUser.subscription.quantity) {
          await this.getSubscriptionVariants()
        }

        if (this.currentUser.subscription.default_payment_method) {
          await this.setDefaultCardLocally()
        }

        await this.connectStripe()
      } catch (error) {
        console.log(error);
        this.errorMessage = 'Something went wrong, please try again.'  
      } finally {
        this.isLoading = false
      }
    },
    getSubscriptionDescription(subscriptionName) {
      if (subscriptionName === 'Year plan') {
        return 'You will receive a full package of services for a year'
      }

      if (subscriptionName === 'Month plan') {
        return 'You will receive a full package of services for a month'
      }

      if (subscriptionName === 'Daily plan') {
        return 'You will receive a full package of services for a day'
      }
    },
    selectSubscription(selectedPrice, amount) {
      this.selectedPrice = selectedPrice
      this.amount = amount
    },
    addMemberEmail(email, index) {
      this.emailsList[index] = email.trim()
    },
    removeEmailField(index) {
      this.emailsList.splice(index, 1)
    },
    addEmailField() {
      this.emailsList.push('')
    },
    async proceedAfterSubscriptionCreation(subscription) {
      const user_update = { subscription }

      await this.updateUserLocally(user_update)
      this.$router.push({ name: 'B2BMembers' })
    },
    checkSubscriptionStatus() {
      setTimeout(async () => {
        const { subscription } = await subscriptionsApi.getUserSubscription()

        if (subscription.status !== UserSubscriptionStatus.Active) {
          this.checkSubscriptionStatus()
          return
        }

        await this.proceedAfterSubscriptionCreation(subscription)
      }, 2500)
    },
    validateMemberEmails() {
      const emailsSet = new Set(this.emailsList)

      if (this.emailsList.length > emailsSet.size) {
        return false
      }

      const emailPattern = new RegExp(/^.+@.+\..+$/)

      for (let i = 0; i < this.emailsList.length; i++) {
        if (!emailPattern.test(this.emailsList[i])) return false
      }

      return true
    },
    async handleMembersInvitation() {
      if (this.emailsList.length === 1 && !this.emailsList[0].length) {
        this.errorMessage = 'You should invite at least one member'
        return
      }

      if (!this.validateMemberEmails()) {
        this.errorMessage = 'Member\'s emails should be unique and valid. Empty field is not allowed'
        return
      }

      this.isLoading = true

      try {
        if (!this.currentUser.subscription.quantity) {
          const payload = {
            subscription_creation_request: {
              price_id: this.selectedPrice,
              emails: this.emailsList,
            },
          }
          const data = await subscriptionsApi.createStripeSubscription(payload)
          const { client_secret } = data

          // If there is no credit and user will be charged right away
          if (client_secret) {
            const payment_method = this.defaultCard ? this.defaultCard.id : { card: this.card }
            const result = await this.stripe.confirmCardPayment(
              client_secret,
              { payment_method }
            )
    
            if (result.error) {
              this.handleStripeErrors(result.error)
              this.isLoading = false
              return
            }

            subscriptionsApi.sendPurchaseNotification()
          }
  
          this.checkSubscriptionStatus()
        } else {
          // Increase quantity
          await subscriptionsApi.addB2BMembers({ emails: this.emailsList })

          const newQuantity = this.currentUser.subscription.quantity + this.emailsList.length
          const user_update = { subscription: { quantity: newQuantity } }

          await this.updateUserLocally(user_update)
          this.$router.push({ name: 'B2BMembers' })
        }
      } catch (error) {
        this.errorMessage = error.response.data.detail
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.loading-container {
  width: 100%;
  max-width: 720px;
  height: 650px;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $whiteColor;
  cursor: wait;
}

.b2b-members-invitation-wrapper {
  width: 100%;
  max-width: 720px;
  height: 600px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow-y: scroll;
  box-sizing: border-box;
  background-color: $whiteColor;

  .error-container {
    width: 100%;
    height: 20px;
    min-height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $redWarningColor;
    font-size: $fontSizeStandart;
  }

  .existing-subscription-info {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .existing-subscription-info-row {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: $fontSizeLarger;
    }
  }

  .subscription-variants {
    width: 100%;
    height: 260px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .subscription-item {
      width: 100%;
      height: 125px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;

      &.selected-subscription {
        border: 1px solid $appActionColor;
      }

      .radio-btn {
        width: 20px;
        height: 20px;
        margin: 0 31px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../../assets/icons/radio-btn-off-icon.svg');
      }

      .active-radio-btn {
        background-image: url('../../../assets/icons/radio-btn-on-icon.svg');
      }

      .subscription-info {
        height: calc(100% - 20px);
        width: 450px;
        margin-right: 31px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .subscription-title {
          font-size: 24px;
        }

        .subscription-description {
          margin-top: 5px;
          font-size: 16px;
          color: $fontColorGray;
        }
      }
    }
  }

  .card-container {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;

    .new-card-title {
      font-size: $fontSizeLarger;
    }

    #card-element {
      width: calc(100% - 20px);
      margin: 20px 0;
      box-sizing: border-box;
      background: $whiteColor;
      border-radius: 4px;
    }
  }

  .email-fields-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;

    .email-field-container {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .delete-email-field-btn {
      width: 24px;
      min-width: 24px;
      height: 45px;
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../../assets/icons/minus-icon.svg');
      cursor: pointer;
    }
  }

  .total-amount-info {
    width: 100%;
    height: 20px;
    margin-top: 10px;
    font-size: $fontSizeLarger;
  }
}
</style>